<template lang="pug">
q-scroll-area(style="height: 50px;" :thumb-style="{display: 'none'}")
    .row.no-wrap
        router-link.link.font-16.line-height-22.text-weight-medium.text-positive.q-px-lg.q-py-sm.marketing.q-mx-md(
            :to="city ? `/${city.slug}/marketing-list` : '/marketing-list'"
        
            title="Акции")
            | Акции
        router-link.link.row.no-wrap.q-px-md.q-py-sm.text-primary.fast-link.q-mr-md(
            v-for="item in links" 
            :to="city ? `/${city.slug}${item.link}` : item.link"
            :title="`${item.icon}`")
            q-icon(
                :name="`etabl:${item.icon}`"
                color="primary"
                size="sm"
            ).q-mr-xs
            .font-16.q-ml-sm.text-no-wrap
                | {{ item.title }}
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'FastLinks',
    setup() {
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const links = [
            {
                title: 'Витамины и микроэлементы',
                link: '/catalog/vitaminy-i-mikroelementy=000000016',
                icon: 'vitamins',
            },
            {
                title: 'Простуда и грипп',
                link: '/catalog/prostuda-i-gripp=000000169',
                icon: 'virus',
            },
            {
                title: 'Красота и уход',
                link: '/catalog/krasota-i-uхod=000000199',
                icon: 'beauty',
            },
            {
                title: 'Медицинские приборы',
                link: '/catalog/pribory-medicinskie=000000346',
                icon: 'devices',
            },
            {
                title: 'Оптика',
                link: '/catalog/optika=000000569',
                icon: 'optics',
            },
            {
                title: 'Ортопедия',
                link: '/catalog/ortopediya=000000496',
                icon: 'ortoped',
            },
        ];

        return {
            links,
            city,
        };
    },
};
</script>

<style lang="scss" scoped>

.fast-link {
    &:hover {
        font-weight: 600;
        color: $positive !important;
    }

    border-radius: 16px; 
    background: $grey-20;
}

.marketing {
    background: #E1F2E9;
    border-radius: 26px;
}
</style>
